

























































































































































































































import { Vue, Component } from "vue-property-decorator";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import {
  checkTaxNumber,
} from "@/validator/globalvalidator";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
const Props = Vue.extend({
  props: [
    "dataListTaxType",
    "dataListTaxInvoicecode",
    "dataListTaxcode",
    "changeTaxType",
    "searchTaxCode",
    "changeCustomerTax",
    "loadingTable",
    "disabledTable",
    "onchangeFormMandatory",
    "onBlur",
    "onFocus"
  ],
  methods: {
    checkTaxNumber
  }
});
@Component
export default class TableTaxDetails extends Props {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formRules = {
    taxType: {
      label: "lbl_tax_type",
      name: "taxType",
      placeholder: "lbl_choose",
      decorator: [
        "taxType",
        {
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    taxCode: {
      label: "lbl_tax_code",
      name: "taxCode",
      placeholder: "lbl_choose",
      decorator: [
        "taxCode",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    customerTaxType: {
      label: "lbl_customer_tax_type",
      name: "customerTaxType",
      placeholder: "lbl_choose",
      decorator: [
        "customerTaxType",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    taxRegistrationNumber: {
      label: "lbl_tax_registration_number",
      name: "taxRegistrationNumber",
      placeholder: "lbl_type_here",
      decorator: [
        "taxRegistrationNumber",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
            {
              validator: checkTaxNumber,
            }
          ],
        },
      ],
    },
    taxRegistrationName: {
      label: "lbl_tax_registration_name",
      name: "taxRegistrationName",
      placeholder: "lbl_type_here",
      decorator: [
        "taxRegistrationName",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    taxInvoiceDate: {
      label: "lbl_tax_invoice_date",
      name: "taxInvoiceDate",
      placeholder: "lbl_choose",
      decorator: [
        "taxInvoiceDate",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    taxInvoiceNumber: {
      label: "lbl_tax_invoice_number",
      name: "taxInvoiceNumber",
      placeholder: "lbl_choose",
      decorator: [
        "taxInvoiceNumber",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
             
          ],
        },
      ],
    },
  };
  onChangeTaxCode(e: string): void {
    this.$emit("change-tax-code", e);
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  onchangeTaxType(e): void {
    const [ decorator_, rules ] = this.formRules.taxCode.decorator as any;
    rules.rules[0].required = e !== TAX_CALCULATION.NONE;
    this.$emit("on-change", e);
  }
}
