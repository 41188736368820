var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        { attrs: { span: 16 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.taxType.label) } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "taxType",
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.$t("lbl_validation_required_error")
                            }
                          ]
                        }
                      ],
                      expression:
                        "[\n          'taxType',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('lbl_validation_required_error'),\n              },\n            ],\n          },\n        ]"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.taxType.name,
                    placeholder: _vm.$t(_vm.formRules.taxType.placeholder),
                    "show-search": true,
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterOption,
                    "allow-clear": true,
                    loading: _vm.loadingTable.taxType,
                    disabled: _vm.disabledTable.taxType
                  },
                  on: { change: _vm.onchangeTaxType }
                },
                _vm._l(_vm.dataListTaxType, function(data, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s("" + data.value) + " ")
                          ]),
                          _vm._v(" " + _vm._s("" + data.value) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.taxCode.label) } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.taxCode.decorator,
                      expression: "formRules.taxCode.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.taxCode.name,
                    placeholder: _vm.$t(_vm.formRules.taxCode.placeholder),
                    "show-search": true,
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterOption,
                    "allow-clear": true,
                    loading: _vm.loadingTable.taxCode,
                    disabled: _vm.disabledTable.taxCode
                  },
                  on: {
                    change: function(value) {
                      return _vm.onChangeTaxCode(value)
                    },
                    search: function(value) {
                      return _vm.searchTaxCode(value)
                    }
                  }
                },
                _vm._l(_vm.dataListTaxcode, function(data) {
                  return _c(
                    "a-select-option",
                    { key: data.id, attrs: { value: data.id } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s("" + data.code) + " ")
                          ]),
                          _vm._v(" " + _vm._s("" + data.code) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.customerTaxType.label) } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "customerTaxType",
                        {
                          initialValue: undefined,
                          rules: [
                            {
                              required: true,
                              message: _vm.$t("lbl_validation_required_error")
                            }
                          ]
                        }
                      ],
                      expression:
                        "\n          [\n            'customerTaxType',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: true,\n                  message: $t('lbl_validation_required_error'),\n                },\n              ],\n            },\n          ]\n        "
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.customerTaxType.name,
                    placeholder: _vm.$t(
                      _vm.formRules.customerTaxType.placeholder
                    ),
                    "show-search": true,
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterOption,
                    "allow-clear": true,
                    loading: _vm.loadingTable.customerTaxType,
                    disabled: _vm.disabledTable.customerTaxType
                  },
                  on: {
                    change: function(value) {
                      return _vm.changeCustomerTax(value)
                    }
                  }
                },
                _vm._l(_vm.dataListTaxInvoicecode, function(data) {
                  return _c(
                    "a-select-option",
                    { key: data.id, attrs: { value: data.id } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s("" + data.code) + " ")
                          ]),
                          _vm._v(" " + _vm._s("" + data.code) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              staticStyle: { "margin-bottom": "unset" },
              attrs: {
                label: _vm.$t(_vm.formRules.taxRegistrationNumber.label)
              }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "taxRegistrationNumber",
                      {
                        initialValue: undefined,
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("lbl_validation_required_error")
                          },
                          {
                            validator: _vm.checkTaxNumber
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'taxRegistrationNumber',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: true,\n                  message: $t('lbl_validation_required_error'),\n                },\n                {\n                  validator: checkTaxNumber,\n                }\n              ],\n            },\n          ]\n        "
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxRegistrationNumber.name,
                  placeholder: _vm.$t(
                    _vm.formRules.taxRegistrationNumber.placeholder
                  ),
                  autocomplete: "off"
                },
                on: {
                  blur: function($event) {
                    return _vm.onBlur($event, "taxRegistrationNumber")
                  },
                  focus: function($event) {
                    return _vm.onFocus($event, "taxRegistrationNumber")
                  }
                }
              })
            ],
            1
          ),
          _c("p", [
            _c(
              "ul",
              { staticStyle: { "font-size": "11px", "padding-left": "12px" } },
              [
                _c("li", [_vm._v(_vm._s(_vm.$t("lbl_password_rule_2")))]),
                _c("li", [_vm._v("15 character")])
              ]
            )
          ]),
          _c(
            "a-form-item",
            {
              attrs: { label: _vm.$t(_vm.formRules.taxRegistrationName.label) }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "taxRegistrationName",
                      {
                        initialValue: undefined,
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'taxRegistrationName',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: true,\n                  message: $t('lbl_validation_required_error'),\n                },\n              ],\n            },\n          ]\n        "
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxRegistrationName.name,
                  placeholder: _vm.$t(
                    _vm.formRules.taxRegistrationName.placeholder
                  ),
                  autocomplete: "off",
                  disabled: _vm.disabledTable.taxRegistrationName
                },
                on: {
                  blur: function($event) {
                    return _vm.onBlur($event, "taxRegistrationName")
                  },
                  focus: function($event) {
                    return _vm.onFocus($event, "taxRegistrationName")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceDate.label) } },
            [
              _c("a-date-picker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "taxInvoiceDate",
                      {
                        initialValue: undefined,
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'taxInvoiceDate',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: true,\n                  message: $t('lbl_validation_required_error'),\n                },\n              ],\n            },\n          ]\n        "
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  placeholder: _vm.$t(_vm.formRules.taxInvoiceDate.placeholder),
                  format: _vm.DEFAULT_DATE_FORMAT,
                  disabled: _vm.disabledTable.taxInvoiceDate
                },
                on: { change: _vm.onchangeFormMandatory }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceNumber.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "taxInvoiceNumber",
                      {
                        initialValue: undefined,
                        rules: [
                          {
                            required: false,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n          [\n            'taxInvoiceNumber',\n            {\n              initialValue: undefined,\n              rules: [\n                {\n                  required: false,\n                  message: $t('lbl_validation_required_error'),\n                },\n           \n              ],\n            },\n          ]\n        "
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxInvoiceNumber.name,
                  placeholder: _vm.$t(
                    _vm.formRules.taxInvoiceNumber.placeholder
                  ),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }